var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "big-data-container" }, [
    _c("div", { staticClass: "content" }, [
      _vm.isDetail && _vm.isBase
        ? _c(
            "div",
            { staticClass: "content-btn-box" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    icon: "el-icon-edit",
                    type: "primary",
                    disabled: _vm.utils.userReadOnly(_vm.user),
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.onEdit.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("修改公司信息")]
              ),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "content-input-box content-input-box__100" }, [
        _c(
          "div",
          { staticClass: "breadcrumb-box" },
          [
            _c(
              "el-breadcrumb",
              { attrs: { "separator-class": "el-icon-arrow-right" } },
              [
                _c("el-breadcrumb-item", { attrs: { to: "/company" } }, [
                  _vm._v("公司管理"),
                ]),
                _c("el-breadcrumb-item", [_vm._v(_vm._s(_vm.title))]),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticStyle: { height: "100%" } },
          [
            _c(
              "el-tabs",
              {
                attrs: { "tab-position": "left" },
                on: { "tab-click": _vm.onTabClick },
                model: {
                  value: _vm.tabName,
                  callback: function ($$v) {
                    _vm.tabName = $$v
                  },
                  expression: "tabName",
                },
              },
              [
                _c(
                  "el-tab-pane",
                  {
                    staticStyle: { height: "100%" },
                    attrs: { name: "company-base", label: "基本信息" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "m-l-20 m-t-10",
                        staticStyle: { height: "100%" },
                      },
                      [
                        _vm.tabName === "company-base"
                          ? _c("company-base", {
                              attrs: {
                                company: _vm.company,
                                "is-detail": _vm.isDetail,
                                "is-add": false,
                              },
                              on: { close: _vm.onClose },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "el-tab-pane",
                  {
                    staticStyle: { height: "100%" },
                    attrs: { name: "authority-config", label: "站点配置" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "m-l-20 m-t-10",
                        staticStyle: { height: "100%" },
                      },
                      [
                        _vm.tabName === "authority-config"
                          ? _c("authority-config", {
                              attrs: { company: _vm.company },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "el-tab-pane",
                  {
                    staticStyle: { height: "100%" },
                    attrs: { name: "system-config", label: "系统配置" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "m-l-20 m-t-10",
                        staticStyle: { height: "100%" },
                      },
                      [
                        _vm.tabName === "system-config"
                          ? _c("system-config", {
                              attrs: { company: _vm.company },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                ),
                _vm.user.tenantId === 0
                  ? _c(
                      "el-tab-pane",
                      {
                        staticStyle: { height: "100%" },
                        attrs: { name: "menu-config", label: "目录配置" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "m-l-20 m-t-10",
                            staticStyle: { height: "100%" },
                          },
                          [
                            _vm.tabName === "menu-config"
                              ? _c("menu-config", {
                                  attrs: { company: _vm.company },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }