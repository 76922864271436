<template>
  <div v-loading="loading" style="height:100%;">
    <div class="tree-box" v-loading="loading" style="height: calc(100% - 90px);">
      <node-tree ref="tree" :data="list" :checked-keys="checkedKeys" :expanded-keys="expandedKeys" :show-checkbox="true" />
    </div>
    <el-row>
      <el-col span="12">
        <el-checkbox v-model="isSelectAll" @change="onChangeSelectAll">{{ isSelectAll ? "全不选" : "全选" }}</el-checkbox>
      </el-col>
      <el-col span="12">
        <div class="right-btns">
          <el-button size="mini" icon="el-icon-circle-check" type="primary" @click="onSubmit">提交</el-button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import nodeTree from "@/components/node-tree";
import { list } from "@/api/menus";
import { edit } from "@/api/business";

export default {
  computed: {
  ...mapGetters(['user']),
  },
  components: {
    nodeTree
  },
  props: {
    company: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      utils: $utils,
      list: [],
      data: [],
      checkedKeys: [],
      expandedKeys: [],
      loading: false,
      isSelectAll: false,
      configs: [],
      extras: [],
      extraConfigsRegx: [ $statics.BUSINESS_CUSTOM_CONFIG_REGX, $statics.VT_CONFIG_REGX, $statics.ASSEMBLE_DATA_CONFIG_REGX ]
    }
  },
  watch: {
    company: function () {
      if (this.company) {
        this.getDatas();
      }
    }
  },
  created () {
    console.log("user-group-authority");
    if (this.company) {
      this.getDatas();
    }
  },
  methods: {
    async getDatas () {
      try {
        this.loading = true;

        const configs = this.company.configs;

        for (const regx of this.extraConfigsRegx) {
          if (regx.test(configs)) {
            const matched = configs.match(regx);
            this.extras.push(matched[0]);
          }
        }

        if (configs) {
          this.configs = configs.split(",");
        } else {
          this.configs = [];
        }

        const data = await list();
        this.data = data;
        this.onToEdit();

        this.setCheckKeys(new Set(this.configs), this.list);
      } catch(err) {
        console.log(err);
        this.$message.error("读取失败");
      } finally {
        this.loading = false;
      }
    },
    onToEdit () {
      this.list = $utils.createNodeTree(this.data, !this.isEdit);
    },
    async onSubmit () {
      try {
        this.loading = true;
        const configs = this.getValue().join();
        const submitData = Object.assign({}, this.company);
        submitData.configs = configs;
        await edit(submitData);
        this.$message.success("权限配置修改成功");
      } catch (err) {
        this.$message.error("权限配置修改失败");
      } finally {
        this.loading = false;
      }
    },
    setCheckKeys(configs, list) {
      for (const item of list) {
        if (configs.has(item.value)) {
          this.checkedKeys.push(item.id);
          if (item.upper) {
            this.expandedKeys.push(item.id);
          }
        }
        this.setCheckKeys(configs, item.children);
      }
    },
    getValue () {
      const values = this.$refs.tree.getValue();
      return values.concat(this.extras);
    },
    onChangeSelectAll () {
      this.$refs.tree.selectAll(this.isSelectAll);
    }
  }
}
</script>
<style lang="scss" scoped>
.tree-box {
  overflow-y: scroll;
  height: calc(100% - 70px);
  margin-bottom: 15px;
}
</style>