var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticStyle: { height: "100%" },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "tree-box",
          staticStyle: { height: "calc(100% - 90px)" },
        },
        [
          _c("node-tree", {
            ref: "tree",
            attrs: {
              data: _vm.list,
              "checked-keys": _vm.checkedKeys,
              "expanded-keys": _vm.expandedKeys,
              "show-checkbox": true,
            },
          }),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: "12" } },
            [
              _c(
                "el-checkbox",
                {
                  on: { change: _vm.onChangeSelectAll },
                  model: {
                    value: _vm.isSelectAll,
                    callback: function ($$v) {
                      _vm.isSelectAll = $$v
                    },
                    expression: "isSelectAll",
                  },
                },
                [_vm._v(_vm._s(_vm.isSelectAll ? "全不选" : "全选"))]
              ),
            ],
            1
          ),
          _c("el-col", { attrs: { span: "12" } }, [
            _c(
              "div",
              { staticClass: "right-btns" },
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      size: "mini",
                      icon: "el-icon-circle-check",
                      type: "primary",
                    },
                    on: { click: _vm.onSubmit },
                  },
                  [_vm._v("提交")]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }