<template>
    <div class="big-data-container">
      <div class="content">
        <div class="content-btn-box" v-if="isDetail && isBase">
          <el-button size="mini" icon="el-icon-edit" type="primary" :disabled="utils.userReadOnly(user)" @click.stop="onEdit">修改公司信息</el-button>
          <!-- <el-button size="mini" icon="el-icon-delete" type="danger" @click="onDelCompany">删除公司</el-button> -->
        </div>
        <div class="content-input-box content-input-box__100">
        <div class="breadcrumb-box">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item to="/company">公司管理</el-breadcrumb-item>
            <el-breadcrumb-item>{{ title }}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div style="height: 100%;">
          <el-tabs tab-position="left" v-model="tabName" @tab-click="onTabClick">
            <el-tab-pane name="company-base" label="基本信息" style="height: 100%">
              <div class="m-l-20 m-t-10" style="height: 100%">
                <company-base v-if="tabName === 'company-base'" :company="company" :is-detail="isDetail" :is-add="false" @close="onClose" />
              </div>
            </el-tab-pane>
            <el-tab-pane name="authority-config" label="站点配置" style="height: 100%">
              <div class="m-l-20 m-t-10" style="height: 100%">
                <authority-config v-if="tabName === 'authority-config'" :company="company" />
              </div>
            </el-tab-pane>
            <el-tab-pane name="system-config" label="系统配置" style="height: 100%">
              <div class="m-l-20 m-t-10" style="height: 100%">
                <system-config v-if="tabName === 'system-config'" :company="company" />
              </div>
            </el-tab-pane>
            <el-tab-pane v-if="user.tenantId === 0" name="menu-config" label="目录配置" style="height: 100%">
              <div class="m-l-20 m-t-10" style="height: 100%">
                <menu-config v-if="tabName === 'menu-config'" :company="company" />
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
      </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';

import bizApi from "@/api/business";

import CompanyBase from "./company-base";
import MenuConfig from "./menu-config";
import SystemConfig from "./system-config";
import AuthorityConfig from './authority-config';

export default {
  computed: {
    ...mapGetters(['user'])
  },
  components: {
    CompanyBase,
    MenuConfig,
    SystemConfig,
    AuthorityConfig
  },
  data () {
    return {
      utils: $utils,
      isDetail: true,
      isBase: true,
      bizId: "",
      tabName: "company-base",
      title: "",
      company: null
    }
  },
  async created () {
    console.log("authori-company-detail");
    this.bizId = this.$route.params.bizId;

    await this.getDatas();
  },
  methods: {
    async getDatas () {
      const res = await bizApi.detail(this.bizId);

      if (!res) {
        this.$message({
          showClose: true,
          type: "error",
          message: `找不到公司（公司ID：${this.bizId}），请确认公司ID没有错误`
        });
        this.$router.push({
          path: "/company"
        });
        return;
      }

      const route = Object.assign({}, this.$route, { title: res.companyName });
      this.$store.dispatch('tagsView/updateVisitedView', route);

      this.title = res.companyName;
      this.company = res;
    },
    onEdit () {
      this.isDetail = false;
      this.title = "修改公司信息";
    },
    onClose (company) {
      this.company = company;
      this.isDetail = true;
      this.title = company.companyName;

      this.$forceUpdate();
    },
    onTabClick () {
      this.isDetail = true;
      this.isBase = this.tabName === "company-base";
    },
    onDelCompany () {
      this.isBase =  this.tabName === "company-base";
      $utils.deleteConfirm(this, "公司", this.title);
    },
    async onDel () {
      try {
        await bizApi.del(this.company.id);
        this.$message.success("公司删除成功");
        setTimeout(() => {
          this.$router.push({
            path: `/company`
          })
        }, 500);
      } catch (err) {
        console.log(err);
        this.$message.error("公司删除失败");
      } finally {
      }
    }
  }
}
</script>