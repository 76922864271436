var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { height: "calc(100% - 40px)", overflow: "auto" } },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loaing",
              rawName: "v-loaing",
              value: _vm.loading.global,
              expression: "loading.global",
            },
          ],
        },
        [
          _c(
            "el-collapse",
            {
              staticClass: "m-r-10",
              model: {
                value: _vm.activeNames,
                callback: function ($$v) {
                  _vm.activeNames = $$v
                },
                expression: "activeNames",
              },
            },
            [
              _c(
                "el-collapse-item",
                { attrs: { name: "logo" } },
                [
                  _c("template", { slot: "title" }, [
                    _c("div", { staticClass: "content-data-title" }, [
                      _vm._v(_vm._s(_vm.nameMap["logo"])),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading.logo,
                          expression: "loading.logo",
                        },
                      ],
                      staticClass: "system-config-item",
                    },
                    [
                      _c(
                        "el-upload",
                        {
                          ref: "upload",
                          attrs: {
                            disabled: _vm.utils.userReadOnly(_vm.user),
                            data: _vm.uploadForm,
                            headers: _vm.headers,
                            accept: "image/png",
                            action: "/api/companies/uploadFile",
                            "on-change": _vm.onLogoUploadChange,
                            "before-upload": _vm.onLogoBeforeUpload,
                            "on-success": _vm.onLogoUploadSuccess,
                            "on-error": _vm.onLogoUploadFailed,
                            "file-list": _vm.uploadFileList,
                            "list-type": "picture",
                          },
                          model: {
                            value: _vm.uploadForm.file,
                            callback: function ($$v) {
                              _vm.$set(_vm.uploadForm, "file", $$v)
                            },
                            expression: "uploadForm.file",
                          },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                slot: "trigger",
                                disabled: _vm.utils.userReadOnly(_vm.user),
                                size: "mini",
                                type: "primary",
                              },
                              slot: "trigger",
                            },
                            [_vm._v("选取图片")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "el-upload__tip",
                              attrs: { slot: "tip" },
                              slot: "tip",
                            },
                            [
                              _vm._v(
                                "只能上传 png 格式的图片，且文件大小不能超过 20 KB"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
              _c(
                "el-collapse-item",
                { attrs: { name: "vt-cnt" } },
                [
                  _c("template", { slot: "title" }, [
                    _c("div", { staticClass: "content-data-title" }, [
                      _vm._v(_vm._s(_vm.nameMap["vt-cnt"])),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading["vt-cnt"],
                          expression: "loading['vt-cnt']",
                        },
                      ],
                      staticClass: "system-config-item",
                    },
                    [
                      _c(
                        "el-form",
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: "12" } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "电压每组个数" } },
                                    [
                                      _c("el-input", {
                                        staticStyle: { width: "100px" },
                                        attrs: { size: "mini" },
                                        on: {
                                          blur: function ($event) {
                                            return _vm.onChangeVtConfig("v")
                                          },
                                        },
                                        model: {
                                          value: _vm.vtConfig.v,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.vtConfig, "v", $$v)
                                          },
                                          expression: "vtConfig.v",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: "12" } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "温度每组个数" } },
                                    [
                                      _c("el-input", {
                                        staticStyle: { width: "100px" },
                                        attrs: { size: "mini" },
                                        on: {
                                          blur: function ($event) {
                                            return _vm.onChangeVtConfig("t")
                                          },
                                        },
                                        model: {
                                          value: _vm.vtConfig.t,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.vtConfig, "t", $$v)
                                          },
                                          expression: "vtConfig.t",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "right-btns m-t-10" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                disabled: _vm.utils.userReadOnly(_vm.user),
                                size: "mini",
                                type: "primary",
                                icon: "el-icon-circle-check",
                              },
                              on: { click: _vm.onSubmitVtConfig },
                            },
                            [_vm._v("提交修改")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
              _c(
                "el-collapse-item",
                { attrs: { name: "assemble-data" } },
                [
                  _c("template", { slot: "title" }, [
                    _c("div", { staticClass: "content-data-title" }, [
                      _vm._v(_vm._s(_vm.nameMap["assemble-data"])),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading["assemble-data"],
                          expression: "loading['assemble-data']",
                        },
                      ],
                      staticClass: "system-config-item",
                    },
                    [
                      _c("el-transfer", {
                        attrs: {
                          data: _vm.assembleDataItemList,
                          titles: [`待选择项目`, "已选择项目"],
                        },
                        model: {
                          value: _vm.assembleDataSelectedItemList,
                          callback: function ($$v) {
                            _vm.assembleDataSelectedItemList = $$v
                          },
                          expression: "assembleDataSelectedItemList",
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "right-btns m-t-10" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                disabled: _vm.utils.userReadOnly(_vm.user),
                                size: "mini",
                                type: "primary",
                                icon: "el-icon-circle-check",
                              },
                              on: { click: _vm.onSubmitAssembleItemConfig },
                            },
                            [_vm._v("提交修改")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }