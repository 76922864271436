var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticStyle: { height: "100%" },
    },
    [
      _vm._l(_vm.alertList, function (alert, index) {
        return _c("el-alert", {
          key: index,
          staticClass: "alert-box",
          attrs: {
            "show-icon": "",
            effect: "dark",
            title: alert.text,
            type: "warning",
            closable: false,
          },
        })
      }),
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "m-t-10",
          attrs: { "label-width": "80px", "label-position": "left" },
        },
        [
          _c("biz-edit", {
            attrs: {
              "data-obj": _vm.form,
              "is-admin": false,
              "is-detail": false,
              "is-add": true,
              except: _vm.exceptCompany,
              "business-id": _vm.company.id,
            },
            on: { "select-company": _vm.onSelectCompany },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { height: "calc(100% - 270px)" } },
        [
          _c("el-transfer", {
            attrs: {
              "filter-placeholder": "请输入站点名称",
              filterable: "",
              data: _vm.data,
              titles: [`未配置站点`, "已配置站点"],
            },
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "right-btns m-t-10" },
        [
          _c(
            "el-button",
            {
              attrs: {
                disabled: _vm.utils.userReadOnly(_vm.user),
                icon: "el-icon-circle-check",
                size: "mini",
                type: "primary",
              },
              on: { click: _vm.onSubmit },
            },
            [_vm._v("提交")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }