<!--
 [20240430]
 1. 自己公司的站点选择性下放给子公司
 比如，自己有 ABCD 四个子公司，有 123456789 九个站点
 其中 A：1234，B：4567，C：6789，D：没有
 以此类推，默认是一个站点都没有；
 2. 如果站点解除绑定，则这个站点中的设备，也会被解除绑定（不存在了）；
 3. 操作权限的下放
 4. 权限赋予必须由上一级公司来操作（跨级操作也不可以）
 5. 更下一级的权限操作和本层级完全一致
-->
<template>
  <div v-loading="loading" style="height: 100%;">
    <el-alert show-icon effect="dark" v-for="(alert, index) in alertList" class="alert-box" :key="index" :title="alert.text" type="warning" :closable="false" />
    <el-form class="m-t-10" ref="form" label-width="80px" label-position="left">
      <biz-edit :data-obj="form" :is-admin="false" :is-detail="false" :is-add="true"
        @select-company="onSelectCompany" :except="exceptCompany" :business-id="company.id" />
    </el-form>
    <div style="height: calc(100% - 270px);">
      <el-transfer filter-placeholder="请输入站点名称" filterable v-model="value" :data="data"
        :titles="[ `未配置站点`, '已配置站点' ]"
      ></el-transfer>
    </div>
    <div class="right-btns m-t-10">
      <el-button :disabled="utils.userReadOnly(user)" icon="el-icon-circle-check" size="mini" type="primary" @click="onSubmit">提交</el-button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import bizApi from "@/api/business";
import siteApi from "@/api/site";

import BizEdit from "@/components/business/edit";

export default {
  computed: {
    ...mapGetters(['user'])
  },
  components: {
    BizEdit
  },
  props: {
    company: undefined
  },
  data () {
    return {
      utils: $utils,
      loading: false,
      selectCompany: null,
      subCompanyList: [],
      value: [],
      valueMap: {},
      data: [],
      proto: [],
      form: {
        tenantId: "",
        businessId: ""
      },
      alertList: [
        { text: "请选择一个次级公司来进行站点配置" },
        { text: "注意！站点配置删除后，挂载在该站点的设备也将会被删除" },
        { text: "三级公司的站点请通过二级公司来配置" }
      ]
    }
  },
  watch: {
    selectCompany: function () {
      this.getSiteList();
    }
  },
  created () {
    this.form.tenantId = this.company.tenantId;
    this.form.businessId = this.company.id;
    this.selectCompany = this.company;
  },
  async mounted () {
    this.form.businessId = "";
    await this.getDatas();
  },
  methods: {
    async getDatas () {
      const res = await siteApi.list({
        page: 0,
        size: 9999999,
        staffCode: `,${this.company.id},`
      });

      this.data = res.data.map(obj => {
        return {
          label: obj.siteName,
          key: obj.id
        }
      });
    },
    async onSelectCompany (company) {
      this.selectCompany = company;
      if (company) {
        await this.getSubCompanyList();
      }
    },
    // 得到所选择公司的所有下级公司
    async getSubCompanyList () {
      const res = await bizApi.list({
        page: 0,
        size: 9999999,
        subsidiaryId: `,${this.selectCompany.id},`
      });

      const subCompanyList = res.data.map(obj => obj.id);
      this.subCompanyList = subCompanyList;
    },
    exceptCompany (company) {
      // 去除条件：1. staffCode !== 自己
      return company.subsidiaryId !== `${this.company.subsidiaryId}${company.id},`;
    },
    async getSiteList() {
      if (!this.selectCompany) return;
      const res = await siteApi.list({
        page: 0,
        size: 9999999,
        staffCode: `,${this.selectCompany.id},`
      });

      this.value = res.data.map(obj => {
        this.valueMap[obj.id] = obj;
        return obj.id;
      });
      this.protoVal = [].concat(this.value);
    },
    async onSubmit () {
      if (!this.selectCompany) {
        this.$message.warning(`请先选择一个需要配置的公司`);
        return;
      }
      const companySiteConfigVal = `${this.selectCompany.companyName}的站点配置`;
      try {
        // 1. 判断新增
        const request = [];
        for (const val of this.value) {
          if (this.protoVal.indexOf(val) < 0) {
            const site = this.valueMap[val];
            if (site.staffCode.indexOf(this.selectCompany.id) < 0) {
              site.staffCode += `${this.selectCompany.id},`
              request.push(siteApi.edit(site));
            }
          }
        }
        // 2. 判断删除
        for (const val of this.protoVal) {
          if (this.value.indexOf(val) < 0) {
            const site = this.valueMap[val];
            let staffCode = site.staffCode;
            // 如果被删除了，则所有次级公司的配置也被删除
            for (const subCompany of this.subCompanyList) {
              staffCode = staffCode.replace(`,${subCompany}`, "");
            }
            site.staffCode = staffCode;
            request.push(siteApi.edit(site));
          }
        }

        await Promise.all(request);
        this.$message.success(`${companySiteConfigVal}修改成功`);
      } catch {
        this.$message.warning(`${companySiteConfigVal}修改失败`);
      }
    }
  }
}
</script>
<style scoped>
::v-deep .el-transfer {
  height: 100%;
}

::v-deep .el-transfer-panel__body {
  height: 100% !important;
}

::v-deep .el-transfer-panel__list {
  height: calc(100% - 104px) !important;
}
</style>