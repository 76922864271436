<template>
  <div style="height: calc(100% - 40px); overflow:auto;">
    <div v-loaing="loading.global">
      <el-collapse v-model="activeNames" class="m-r-10">
        <el-collapse-item name="logo">
          <template slot="title">
            <div class="content-data-title">{{ nameMap["logo"] }}</div>
          </template>
          <div v-loading="loading.logo" class="system-config-item">
            <el-upload
              ref="upload"
              :disabled="utils.userReadOnly(user)"
              v-model="uploadForm.file"
              :data="uploadForm"
              :headers="headers"
              accept="image/png"
              action="/api/companies/uploadFile"
              :on-change="onLogoUploadChange"
              :before-upload="onLogoBeforeUpload"
              :on-success="onLogoUploadSuccess"
              :on-error="onLogoUploadFailed"
              :file-list="uploadFileList"
              list-type="picture"
            >
              <el-button :disabled="utils.userReadOnly(user)" slot="trigger" size="mini" type="primary">选取图片</el-button>
              <div slot="tip" class="el-upload__tip">只能上传 png 格式的图片，且文件大小不能超过 20 KB</div>
            </el-upload>
          </div>
        </el-collapse-item>
        <el-collapse-item name="vt-cnt">
          <template slot="title">
            <div class="content-data-title">{{ nameMap["vt-cnt"] }}</div>
          </template>
          <div v-loading="loading['vt-cnt']" class="system-config-item">
            <el-form>
              <el-row>
                <el-col span="12">
                  <el-form-item label="电压每组个数">
                    <el-input size="mini" style="width: 100px;" @blur="onChangeVtConfig('v')" v-model="vtConfig.v" />
                  </el-form-item>
                </el-col>
                <el-col span="12">
                  <el-form-item label="温度每组个数">
                    <el-input size="mini" style="width: 100px;" @blur="onChangeVtConfig('t')" v-model="vtConfig.t" />
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
            <div class="right-btns m-t-10">
              <el-button :disabled="utils.userReadOnly(user)" size="mini" type="primary" icon="el-icon-circle-check" @click="onSubmitVtConfig">提交修改</el-button>
            </div>
          </div>
        </el-collapse-item>
        <el-collapse-item name="assemble-data">
          <template slot="title">
            <div class="content-data-title">{{ nameMap["assemble-data"] }}</div>
          </template>
          <div v-loading="loading['assemble-data']" class="system-config-item">
            <el-transfer v-model="assembleDataSelectedItemList" :data="assembleDataItemList"
            :titles="[ `待选择项目`, '已选择项目' ]"
            ></el-transfer>
            <div class="right-btns m-t-10">
              <el-button :disabled="utils.userReadOnly(user)" size="mini" type="primary" icon="el-icon-circle-check" @click="onSubmitAssembleItemConfig">提交修改</el-button>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import { getToken } from '@/utils/auth';
import bizApi from "@/api/business";

export default {
  props: {
    company: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapGetters(['user']),
    headers: function () {
      const token = getToken();
      return { 
        Authorization: token
      };
    }
  },

  data () {
    return {
      utils: $utils,
      activeNames: [ "logo", "vt-cnt" , "assemble-data" ],
      nameMap: {
        "logo": "公司 LOGO",
        "vt-cnt": "总控电压、温度每组数量",
        "assemble-data": "总控数据默认显示项目"
      },
      loading: {
        global: false,
        logo: false,
        "vt-cnt": false,
        "assemble-data": false
      },
      height: 0,
      uploadFile: null,
      uploadFileList: [],
      uploadForm: {
        file: "",
        id: ""
      },
      vtConfig: {
        v: 24,
        t: 16
      },
      vtConfigBk: {
        v: 0,
        t: 0
      },
      assembleDataItemList: $statics.ASSEMBLE_DATA_ITEM_LIST.map(obj => { return { key: obj.value, label: obj.label }}),
      assembleDataSelectedItemList: [].concat($statics.DEFAULT_CHECKED_ASSEMBLE_DATA_ITEM_LIST),
      assembleDataSelectedItemListBk: []
    }
  },
  created () {
    console.log("system-config");
    this.uploadForm.id = `pcpf-logo-${this.company.id}`;
    this.height = document.body.scrollHeight - 66;

    this.getDatas();
  },
  methods: {
    async getDatas () {
      try {
        this.loading.global = true;

        const { configs } = this.company;
        if ($statics.BUSINESS_CUSTOM_CONFIG_REGX.test(configs)) {
          let customConfig = configs.match($statics.BUSINESS_CUSTOM_CONFIG_REGX)[1];
          customConfig = JSON.parse(customConfig);
          if (customConfig.logo) {
            const file = {
              name: `${this.uploadForm.id}.png`,
              url: `${process.env.VUE_APP_BASE_URL}/wxapp/upload/${this.uploadForm.id}.png`
            };
            this.uploadFileList.push(file);
          }
        }

        if ($statics.VT_CONFIG_REGX.test(configs)) {
          let viewVtConfig = configs.match($statics.VT_CONFIG_REGX)[1];
          viewVtConfig = JSON.parse(viewVtConfig);
          this.vtConfig = Object.assign({}, viewVtConfig);
          this.vtConfigBk = Object.assign({}, viewVtConfig);
        }

        if ($statics.ASSEMBLE_DATA_CONFIG_REGX.test(configs)) {
          let assembleDataConfig = configs.match($statics.ASSEMBLE_DATA_CONFIG_REGX)[1];
          const assembleDataSelectedItemList = assembleDataConfig.split(",");
          this.assembleDataSelectedItemList = assembleDataSelectedItemList;
          this.assembleDataSelectedItemListBk = [].concat(assembleDataSelectedItemList);
        }

      } finally {
        this.loading.global = false;
      }
    },
    onLogoUploadChange (file, fileList) {
      if (file.status === "ready") {
        if (file.size / 1024 > 20) {
          this.$message.warning("文件大小不能超过 20 KB，请重新选择");
          this.loading.logo = false;

          return;
        }

        this.loading.logo = true;
        this.uploadFile = file;
      }
    },
    onLogoBeforeUpload () {
      return this.loading.logo;
    },
    async onLogoUploadSuccess (response, file, fileList) {
      if (fileList.length > 1) {
        const file = fileList.shift();
        this.fileList = [file];
      }

      const submit = JSON.stringify({
        logo: true
      });

      const submitConfigs = `custom:${submit}:custom`;

      this.onSubmit($statics.BUSINESS_CUSTOM_CONFIG_REGX, submitConfigs, "logo");
    },
    onLogoUploadFailed (err) {
      console.log(err);
      this.loading.logo = false;
    },
    async onChangeVtConfig (key) {

      const val = Number(this.vtConfig[key]);
      if (isNaN(val)) {
        this.$message.warning("个数请使用数字");
        this.vtConfig[key] = this.vtConfigBk[key];
        return;
      }
      if (val ===  this.vtConfigBk[key]) return;
      if (val === 0) {
        this.$message.warning("个数不能设置为0，请重新设置");
        this.vtConfig[key] = this.vtConfigBk[key];
        return;
      }
    },
    onSubmitVtConfig () {
      this.vtConfigBk = Object.assign({}, this.vtConfig);

      let vtConfigStr = JSON.stringify(this.vtConfig);
      vtConfigStr = `vt-config:${vtConfigStr}:vt-config`;

      this.onSubmit($statics.VT_CONFIG_REGX, vtConfigStr, "vt-cnt");
    },
    onSubmitAssembleItemConfig () {
      let configs = this.assembleDataSelectedItemList.join();
      configs = `assemble-data-config:${configs}:assemble-data-config`;

      this.onSubmit($statics.ASSEMBLE_DATA_CONFIG_REGX, configs, "assemble-data");
    },
    async onSubmit (regx, value, name) {

      const collapse = this.nameMap[name];
      try {
        this.loading[name] = true;

        const bizData = Object.assign({}, this.company);
        let configs = bizData.configs;

        if (regx.test(configs)) {
          configs = configs.replace(regx, value);
        } else {
          configs += `,${value}`;
        }
        bizData.configs = configs;

        await bizApi.edit(bizData);
        this.$message.success(`${collapse}配置修改成功`);
      } catch {
        this.$message.success(`${collapse}配置修改失败`);
      } finally {
        this.loading[name] = false;
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.system-config-item {
  margin-bottom: 20px;

  >:last-child {
    margin: 10px 0px 0px 100px;
  }
}
</style>